<template>
  <div class="public-scroll-transparent public-page-style">
    <div slot="header" class="clearfix">
      <el-form :inline="true">
        <el-button
          type="primary"
          plain
          size="mini"
          class="iot-menu-create"
          @click="create()"
          >新增根目录</el-button
        >
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      row-key="menuId"
      border
      stripe=""
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      >
      <el-table-column label="菜单名称" prop="menuName" width="180px">
      </el-table-column>
      <el-table-column label="图标" prop="icon" width="100px">
      </el-table-column>
      <el-table-column label="路径" prop="menuPath" width="250px">
      </el-table-column>
      <el-table-column label="组件名称" prop="menuTag" width="200px">
      </el-table-column>
      <el-table-column label="菜单类型" width="100px">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.menuType == 0">菜单</el-tag>
          <el-tag type="success" v-else-if="scope.row.menuType == 1"
            >服务</el-tag
          >
          <el-tag v-else-if="scope.row.menuType == 2">按钮</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="菜单排序"
        prop="menuOrder"
        width="100px"
      ></el-table-column>
      <el-table-column label="菜单标识" prop="permission" width="210px">
      </el-table-column>
      <el-table-column label="操作" prop="desc">
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            size="mini"
            @click.native="create(scope.row.menuId)"
            >新增</el-button
          >
          <el-button
            size="mini"
            type="primary"
            plain
            @click.native="edit(scope.row.menuId)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            @click.native="del(scope.row.menuId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
    top="3%"
      :title="formTitle"
      :show-close="false"
      :visible.sync="menuDialogVisinbile"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <el-form :model="menuData" ref="menuRef" :rules="rules">
        <el-form-item
          label="菜单类型"
          :label-width="formLabelWidth"
          prop="menuType"
        >
          <el-radio
            v-model="menuData.menuType"
            v-for="item in constant.menuType"
            :label="item.key"
            :key="item.key"
            >{{ item.value }}</el-radio
          >
        </el-form-item>
        <el-form-item
          label="菜单名称"
          :label-width="formLabelWidth"
          prop="menuName"
        >
          <el-input v-model="menuData.menuName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          prop="menuPath"
          label="路径"
          :label-width="formLabelWidth"
          v-if="menuData.menuType != 2"
        >
          <el-input v-model="menuData.menuPath" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          prop="menuTag"
          label="组件名称"
          :label-width="formLabelWidth"
          v-if="menuData.menuType != 2"
        >
          <el-input v-model="menuData.menuTag" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          prop="permission"
          label="菜单标识"
          :label-width="formLabelWidth"
          v-if="menuData.menuType == 2"
        >
          <el-input v-model="menuData.permission" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          prop="icon"
          label="显示图标"
          :label-width="formLabelWidth"
          v-if="menuData.menuType != 2"
        >
          <el-input v-model="menuData.icon" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="菜单排序"
          :label-width="formLabelWidth"
          prop="menuOrder"
        >
          <el-input v-model="menuData.menuOrder" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="服务状态"
          prop="menuState"
          v-show="menuData.menuType == 1"
          :label-width="formLabelWidth"
        >
          <el-radio
            v-model="menuData.menuState"
            v-for="item in constant.menuState"
            :label="item.key"
            :key="item.key"
            >{{ item.value }}</el-radio
          >
        </el-form-item>
        <el-form-item
          v-show="menuData.menuType == 1"
          label="备注信息"
          :label-width="formLabelWidth"
          prop="remark"
        >
          <el-input
            type="textarea"
            placeholder="请输入备注信息"
            v-model="menuData.remark"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button type="primary" plain size="mini" @click="submitForm"
          >确 定</el-button
        >
        <el-button
          type="info"
          plain
          size="mini"
          @click="menuDialogVisinbile = false"
          >取 消</el-button
        >
       
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { PRIMARY_COLOR } from "../util/theme";
import { menuState, menuType } from "../util/constant";
let that;
export default {
  name: "MenuManagement",
  data() {
    return {
      constant: {
        menuState,
        menuType,
      },
      theme: { PRIMARY_COLOR },
      tableData: [],
      menuData: {
        parentId: 0,
        menuType: 0,
        menuName: "",
        menuTag: "",
        menuPath: "",
        icon: "",
        permission: "", //纯字符串
        menuId: "",
        menuOrder: 0,
        menuState: 0,
        remark: "",
      },
      rules: {
        menuName: [
          { required: true, message: "请输入菜单名称", trigger: "blur" },
        ],
        menuType: [
          { required: true, message: "请选择菜单类型", trigger: "blur" },
        ],
        menuPath: [
          { required: true, message: "请输入菜单路径", trigger: "blur" },
        ],
        menuTag: [
          { required: true, message: "请输入组件名称", trigger: "blur" },
        ],
        permission: [
          { required: true, message: "请输入菜单标识", trigger: "blur" },
        ],
        menuState: [
          { required: true, message: "请选择服务状态", trigger: "blur" },
        ],
      },
      isCreate: true,
      formTitle: "",
      menuDialogVisinbile: false,
      formLabelWidth: "120px",
    };
  },
  methods: {
    getMenuNoPage() {
      that.$api.menuManagement.getMenu().then((response) => {
        that.tableData = response.data;
      });
    },
    create(parentId = 1) {
      that.$set(that.menuData, "parentId", parentId);
      that.menuData={
        parentId: parentId,
        menuType: 0,
        menuName: "",
        menuTag: "",
        menuPath: "",
        icon: "",
        permission: "", //纯字符串
        menuId: "",
        menuOrder: 0,
        menuState: 0,
        remark: "",
      },
      that.isCreate = true;
      that.formTitle = "新增菜单";
      that.menuDialogVisinbile = true;
    },
    edit(menuId) {
      new Promise((resolve) => {
        that.$api.menuManagement
          .getSingleMenuDetail(menuId)
          .then((response) => {
            if (response.code == 200) {
              resolve(response.data);
            }
          });
      }).then((res) => {
        that.$nextTick(() => {
          that.menuData = Object.assign({}, that.menuData, res);
        });
        that.menuDialogVisinbile = true;
        that.isCreate = false;
        that.formTitle = "修改菜单";
      });
    },
    del(menuId) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.menuManagement.deleteMenu(menuId).then(() => {
            that.getMenuNoPage();
            that.$message({
              message: "删除成功",
              type: "success",
            });
          });
        });
    },
    submitForm() {
      that.$refs["menuRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.menuManagement.createMenu(that.menuData)
            : that.$api.menuManagement.editMenu(that.menuData);
          _api.then((res) => {
            
            if(res.code==200){
              that.getMenuNoPage();
            that.menuDialogVisinbile = false;
            that.isCreate = true;
            that.$refs.menuRef.resetFields();
              that.$message({
              message: "提交成功",
              type: "success",
            });
            }
            
          });
        }
      });
    },
    handleClose() {
      that.$refs.menuRef.resetFields();
    },
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.getMenuNoPage();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.el-dropdown-link {
  cursor: pointer;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  font-size: 14px;
  margin-bottom: 20px;
}
.iot-menu-create {
  float: right;
  margin: 10px;
}
.iot-table-color {
  background: oldlace;
}
</style>
